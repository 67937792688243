import React from 'react';

import ReactDOM from 'react-dom';
import {VipCenter} from './page/vipcenter/VipCenter';
import Bridge from "./Bridge";
import {AccountRecords} from "./page/AccountRecords/AccountRecords";
import {Coupons} from "./page/coupons/Coupons";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Download} from "./page/download/Download";
import {Trace} from "./page/Trace/Trace";
import AppIntro, {AppIntroMoFang} from "./page/download/AppInfo";
import CalenderPage from "./rubick/Calender";
import RubickBridge from "./rubick/RubickBridge";
import {Bianqian} from "./rubick/bianqian/Bianqian";
import {WangYiYun} from "./rubick/wangyi/WangYiYun";
import {TenDataCommonV2Component} from "./rubick/tenData/TenDataCommonV2Component";
import {TenDataList} from "./rubick/tenData/TenDataList";
import {ShiwuhuangjinV2} from "./rubick/huangjin/ShiwuhuangjinV2";
import {ZhihuangjinV2} from "./rubick/huangjin/ZhihuangjinV2";
import {Shuzibi} from "./rubick/huangjin/Shuzibi";
import {HuilvV2} from "./rubick/huangjin/HuilvV2";
import {YoujiaV2} from "./rubick/Youjia/YoujiaV2";
import {WidgetHelper} from "./rubick/WidgetHelper";
import {CommonHelper} from "./rubick/CommonHelper";
import WaterTracker from "./rubick/water/Water";
import {CommonHelperForAndroid} from "./rubick/CommonHelperForAndroid";
import {WidgetHelperForAndroid} from "./rubick/WidgetHelperForAndroid";
import {ZhibiaoDetail, ZhibiaoMenu} from "./rubick/dataZhibiao/Zhibiao";
import {PriceDetail} from "./rubick/price/Price";
import EditWeather from "./rubick/brickEdit/EditWeatherCity";
import {ConfigProvider} from "@nutui/nutui-react";
import WeChatContact from "./rubick/WeChat";
import ScanLogin from "./rubick/login/ScanLogin";
import ActivationPage from "./page/Activation/ActivationPage";
import ActivationCodeGenerator from "./page/Activation/ActivationCodeGenerator";
import QAIOS from "./page/Qa/Qa_ios";
import TutorialIOS from "./page/How2Use/TutorialIOS";
import TripDataInfo from "./page/TripDataInfo/TripDataInfo";
import TutorialAndroid from "./page/How2Use/TutorialAndroid";
import TutorialAndroidAddWidget from "./page/How2Use/TutorialAndroidAddWidget";
import QAAndroid from "./page/Qa/QA_Android";
import QA_Android from "./page/Qa/QA_Android";
// import ActivationCodeGenerator from "./page/Activation/ActivationCodeGenerator";

export  const darkTheme = {
    nutuiCalendarDayActiveBorderRadius: "10pt",
    nutuiCalendarDisableColor: "gray",
    nutuiCellBoxShadow:'0px 0px 0px 0px rgba(237, 238, 241, 1)'
}
ReactDOM.render(
    <ConfigProvider theme={darkTheme}>

    <Router>
        <Routes>
            <Route path="/qa_ios" element={<QAIOS/>}/>
            <Route path="/qa_android" element={<QAAndroid/>}/>
            <Route path="/how_to_use_ios" element={<TutorialIOS/>}/>
            <Route path="/how_to_use_android" element={<TutorialAndroid/>}/>
            <Route path="/how_to_add_widget_android" element={<TutorialAndroidAddWidget/>}/>



            <Route path="/tripDataInfo" element={<TripDataInfo/>}/>
            <Route path="/activation" element={<ActivationPage/>}/>
            <Route path="/activation_ge" element={<ActivationCodeGenerator/>}/>
            <Route path="/edit/weather" element={<EditWeather/>}/>
            <Route path="/apk" element={<Download/>}/>
            <Route path="/weixin" element={<WeChatContact/>}/>
            <Route path="/bridge" element={<Bridge/>}/>
            <Route path="/rubick_bridge" element={<RubickBridge/>}/>

            <Route path="/vip_center" element={<VipCenter/>}/>
            <Route path="/my_coupons" element={<Coupons/>}/>
            <Route path="/my_records" element={<AccountRecords/>}/>
            {/*<Route path="/feedback" element={<FeedBack/>}/>*/}

            <Route path="/" element={<AppIntro/>}/>
            <Route path="/mofang" element={<AppIntroMoFang/>}/>
            <Route path="/login_scan" element={<ScanLogin/>}/>
            <Route path="/calender" element={<CalenderPage/>}/>

            <Route path="/core_data/water" element={<WaterTracker/>}/>
            <Route path="/core_data/bianqian" element={<Bianqian/>}/>
            <Route path="/core_data/addWidget" element={<WidgetHelper/>}/>
            <Route path="/core_data/addWidget_android" element={<WidgetHelperForAndroid/>}/>
            <Route path="/core_data/helper" element={<CommonHelper/>}/>
            <Route path="/core_data/helper_android" element={<CommonHelperForAndroid/>}/>

            <Route path="/core_data/tenList" element={<TenDataList/>}/>
            <Route path="/core_data/wangyiyun" element={<WangYiYun/>}/>
            <Route path="/core_data/shuzibi" element={<Shuzibi/>}/>
            <Route path="/trace" element={<Trace/>}/>
            <Route path="/core_data/youjia" element={<YoujiaV2/>}/>
            <Route path="/core_data/huilv" element={<HuilvV2/>}/>
            <Route path="/core_data/zhihuangjin" element={<ZhihuangjinV2/>}/>
            <Route path="/core_data/shiwuhuangjin" element={<ShiwuhuangjinV2/>}/>
            <Route path="/core_data/ppi_y" element={<TenDataCommonV2Component aid="td.ppi_y"/>}/>
            <Route path="/core_data/cpi_m" element={<TenDataCommonV2Component title="中国CPI月率" aid="td.cpi_m"/>}/>
            <Route path="/core_data/cpi_y" element={<TenDataCommonV2Component title="中国CPI年率" aid="td.cpi_y"/>}/>
            <Route path="/core_data/exp_y"
                   element={<TenDataCommonV2Component title="中国以美元计算出口年率" aid="td.exp_y"/>}/>
            <Route path="/core_data/in_y"
                   element={<TenDataCommonV2Component title="中国以美元计算进口年率报告" aid="td.in_y"/>}/>
            <Route path="/core_data/maoyi"
                   element={<TenDataCommonV2Component title="中国以美元计算贸易帐" aid="td.maoyi"/>}/>
            <Route path="/core_data/gongyeup"
                   element={<TenDataCommonV2Component title="中国规模以上工业增加值年率" aid="td.gongyeup"/>}/>
            <Route path="/core_data/pmi_zzy"
                   element={<TenDataCommonV2Component title="中国官方制造业PMI" aid="td.pmi_zzy"/>}/>
            <Route path="/core_data/pmi_caixin_zzy"
                   element={<TenDataCommonV2Component title="中国财新制造业PMI终值" aid="td.pmi_caixin_zzy"/>}/>
            <Route path="/core_data/pmi_caixin_fwy"
                   element={<TenDataCommonV2Component title="中国财新服务业PMI" aid="td.pmi_caixin_fwy"/>}/>
            <Route path="/core_data/whcb"
                   element={<TenDataCommonV2Component title="中国外汇储备(美元)" aid="td.whcb"/>}/>
            <Route path="/core_data/m2_y"
                   element={<TenDataCommonV2Component title="中国M2货币供应年率" aid="td.m2_y"/>}/>
            <Route path="/core_data/gdp_y"
                   element={<TenDataCommonV2Component title="中国GDP年率报告" aid="td.gdp_y"/>}/>


            <Route path="/core_data/dc_usa_interest_rate_decision"
                   element={<TenDataCommonV2Component title="美联储利率" aid="td.dc_usa_interest_rate_decision"/>}/>
            <Route path="/core_data/dc_australia_interest_rate_decision"
                   element={<TenDataCommonV2Component title="澳洲联储利率"
                                                      aid="td.dc_australia_interest_rate_decision"/>}/>
            <Route path="/core_data/dc_english_interest_rate_decision"
                   element={<TenDataCommonV2Component title="英国央行利率"
                                                      aid="td.dc_english_interest_rate_decision"/>}/>
            <Route path="/core_data/dc_india_interest_rate_decision"
                   element={<TenDataCommonV2Component title="印度利率" aid="td.dc_india_interest_rate_decision"/>}/>
            <Route path="/core_data/dc_russia_interest_rate_decision"
                   element={<TenDataCommonV2Component title="俄罗斯利率" aid="td.dc_russia_interest_rate_decision"/>}/>
            <Route path="/core_data/dc_brazil_interest_rate_decision"
                   element={<TenDataCommonV2Component title="巴西利率决" aid="td.dc_brazil_interest_rate_decision"/>}/>
            <Route path="/core_data/dc_switzerland_interest_rate_decision"
                   element={<TenDataCommonV2Component title="瑞士央行利率"
                                                      aid="td.dc_switzerland_interest_rate_decision"/>}/>
            <Route path="/core_data/dc_interest_rate_decision"
                   element={<TenDataCommonV2Component title="欧洲央行利率" aid="td.dc_interest_rate_decision"/>}/>
            <Route path="/core_data/dc_japan_interest_rate_decision"
                   element={<TenDataCommonV2Component title="日本利率" aid="td.dc_japan_interest_rate_decision"/>}/>


            <Route path="/core_data/dc_usa_gdp"
                   element={<TenDataCommonV2Component title="美国GDP" aid="td.dc_usa_gdp"/>}/>
            <Route path="/core_data/dc_usa_unemployment_rate"
                   element={<TenDataCommonV2Component title="美国失业率" aid="td.dc_usa_unemployment_rate"/>}/>
            <Route path="/core_data/dc_usa_cpi"
                   element={<TenDataCommonV2Component title="美国CPI月率" aid="td.dc_usa_cpi"/>}/>
            <Route path="/core_data/dc_eurozone_gdp_yoy"
                   element={<TenDataCommonV2Component title="欧元区季度GDP年率" aid="td.dc_eurozone_gdp_yoy"/>}/>
            <Route path="/core_data/dc_eurozone_cpi_mom"
                   element={<TenDataCommonV2Component title="欧元区CPI月率" aid="td.dc_eurozone_cpi_mom"/>}/>
            <Route path="/core_data/dc_eurozone_cpi_yoy"
                   element={<TenDataCommonV2Component title="欧元区CPI年率" aid="td.dc_eurozone_cpi_yoy"/>}/>
            <Route path="/core_data/dc_eurozone_ppi_mom"
                   element={<TenDataCommonV2Component title="欧元区PPI月率" aid="td.dc_eurozone_ppi_mom"/>}/>
            <Route path="/core_data/zhibiao/home" element={<ZhibiaoMenu/>}/>
            <Route path="/core_data/zhibiao" element={<ZhibiaoDetail/>}/>


            <Route path="/core_data/price" element={<PriceDetail/>}/>


        </Routes>
    </Router>
    </ConfigProvider>,
    document.getElementById('root')
);