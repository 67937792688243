import React, {useEffect, useState} from 'react';
import { Row, Col, Button} from '@nutui/nutui-react'; // 使用 NutUI 的组件
import wechatQr from './wx2.png'; // 替换为你的二维码图片路径
import './../RubickJSBridge'
import {eventReport, toast} from "../RubickJSBridge";

const WeChatContact = () => {
    const weChatNumber = "mofanginfo"; // 替换为你的微信号
    const [copySuccess, setCopySuccess] = useState(false);


    useEffect(()=>{
        eventReport("pv", "WeChatContactPage")
    },[])
    const handleCopy = () => {
        navigator.clipboard.writeText(weChatNumber)
            .then(() => {
                setCopySuccess(true);
                toast("微信号已复制到剪贴板","success")
            })
            .catch(() => {
                toast("复制失败，请重试","error ")

            });
    };

    return (
        <div style={styles.container} >
            <Row justify="center" align="center" style={styles.row}>
                <Col span="24" style={styles.header}>
                    <h2>添加企业微信</h2>
                    <p>扫一扫二维码添加微信，掌握更多活动动态</p>
                </Col>
                <Col span="24" style={styles.qrContainer}>
                    <img src={wechatQr} alt="WeChat QR Code" style={styles.qrCode} />
                </Col>

                <Col span="24" >
                    <div style={styles.guide}>
                        <h3>添加微信</h3>
                        <ul style={styles.benefitsList}>
                            <li>朋友圈不定期发布送会员激活码活动</li>
                            <li>加入官方群聊，掌握最新数据信息</li>
                        </ul>
                    </div>

                </Col>
            </Row>
        </div>
    );
};

const styles = {
    container: {
        textAlign: 'center',
        padding: '20px',
        backgroundColor: '#181818',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    row: {
        width: '100%',
    },
    header: {
        marginBottom: '20px',
    },
    qrContainer: {
        marginBottom: '20px',
    },
    qrCode: {
        width: '80vw',
        maxWidth: '300px',
        borderRadius: '10px',
    },
    weChatNumber: {
        fontSize: '20px',
        color: '#fff',
    },
    copyButton: {
        marginTop: '20px',
    },
    guide: {
        marginTop: '30px',
        textAlign: 'left',
        padding: '20px',
        backgroundColor: '#fff',
        color:"#333",
        fontStyle:'bold',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    },
    benefitsList: {
        listStyleType: 'disc',
        paddingLeft: '20px',
        fontSize: '16px',
        color: '#555',
    }
};

export default WeChatContact;